<template>
  <v-tabs
    height="unset"
    :dark="dark"
    left
    :color="color"
  >
    <template v-for="nav in navigation">
      <v-tab
        v-if="!nav.hidden"
        :key="nav.title"
        :to="nav.src"
        class="font-weight-regular text-none text-subtitle-1 px-0 mx-3"
        active-class="font-weight-negrita"
      >
        {{ nav.title }}
      </v-tab>
    </template>
  </v-tabs>
</template>

<script>
  import { get } from 'vuex-pathify'
  export default {
    props: {
      dark: {
        type: Boolean,
        default: false,
      },
      color: {
        type: [String, Boolean],
        default: false,
      },
    },
    data () {
      return {
        currentItem: {
          title: 'Panel',
          src: '/',
        },

      }
    },
    computed: {
      group: get('user/group'),
      navigation () {
        return [
          {
            title: 'Empleados',
            src: '/',
          },
        ]
      },
    },
  }
</script>

<style scoped>
.v-tab{
  min-width: unset;
}
</style>
