<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template #activator="{ attrs, on }">
      <v-avatar
        class="ml-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-img
          v-if="darkProfile"
          src="@/assets/logoDark.svg"
          contain
        />
        <v-img
          v-else
          src="@/assets/logo.svg"
          contain
        />
      </v-avatar>
    </template>

    <v-list
      :tile="false"
      flat
      nav
    >
      <div
        v-if="userData"
        class="text-caption text-center font-weight-medium"
      >
        {{ userData.name }}
      </div>
      <div class="text-subtitle-2 text-center font-weight-medium py-2">
        {{ userData.cuit | formatCuit }}
      </div>

      <template v-for="(p, i) in items">
        <v-divider
          v-if="p.divider"
          :key="`divider-${i}`"
          class="mb-2 mt-2"
        />

        <app-bar-item
          v-else
          :key="`item-${i}`"
        >
          <v-list-item
            :to="p.path"
            @click="handleClick(p.meta)"
          >
            <v-list-item-title v-text="p.meta.title" />
          </v-list-item>
        </app-bar-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  import { get, sync } from 'vuex-pathify'
  import { formatCuit } from '@/util/formatters'
  export default {
    name: 'DefaultAccount',
    filters: { formatCuit },
    computed: {
      items () {
        const items = []
        const itemsMeta = this.$router.options.routes.filter(item => item.children?.length)
        itemsMeta.forEach(element => {
          element.children.forEach(children => {
            if (children?.meta?.accountMenu) {
              items.push(children)
            }
          })
        })
        return [...items, { divider: true }, { meta: { title: 'Salir' }, path: '/logout' }]
        // return [...items, { meta: { title: 'Modificar contraseña', class: 'text-subtitle-2', action: 'changePassword', icon: 'refresh' } }, { divider: true }, { meta: { title: 'Salir' }, path: '/logout' }]
      },

      userData: get('user'),
      darkProfile () {
        return this.$route?.meta?.darkProfile
      },
      ...sync('app', ['modalChangePassword']),
    },
    methods: {
      handleClick (event) {
        if (event?.action === 'changePassword') {
          this.modalChangePassword = true
        }
      },
    },
  }
</script>
